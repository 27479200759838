<template>
    <div class="main simple">
        <simple-header />
        <div class="simple-logo">
            <div class="simple-logo-box">
                <img class="simple-logo-img" src="@a/img/login-logo.png" width="128" height="50" alt="kamisoul">
                <h3 class="simple-title">修改密码</h3>
            </div>
        </div>
        <div class="simple-main">
            <div class="simple-body">
                <el-form ref="ruleForm" :model="ruleForm" class="simple-form">
                    <el-form-item prop="phone" label="手机号">
                        <el-input v-model="ruleForm.phone" disabled :maxlength="11" />
                    </el-form-item>
                    <el-form-item prop="code" :rules="{ required: true, message: '请输入验证码', trigger: 'blur' }">
                        <el-input v-model="forgetForm.code" placeholder="验证码" clearable>
                            <a slot="append" class="input-append-btn" href="javascript:;">获取验证码</a>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password" label="密码" :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }, { min: 4, max: 16, message: '长度在 4 到 16 个字符', trigger: 'blur' }]">
                        <span slot="label" class="form-label-hastips">密码<i class="form-label-tips">4~16个字符</i></span>
                        <el-input v-model="ruleForm.password" show-password :maxlength="16" clearable @keyup.enter.native="onSubmit" />
                    </el-form-item>
                    <a href="javascript:;" class="btn-primary block" @click="onSubmit">提交</a>
                </el-form>
            </div>
        </div>
        <simple-footer />
    </div>
</template>
<script>
import mixin from '@c/mixins'
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import { getUserAccount, repassword } from '@api'
export default {
    name: 'MySetPassword',
    components: {
        simpleHeader,
        simpleFooter
    },
    mixins: [mixin],
    data() {
        return {
            ruleForm: {
                phone: '',
                password: '',
                code: ''
            }
        }
    },
    methods: {
        // 获取用户信息
        // async getAccount() {
        //     console.log(444)
        //     const res = await getUserAccount()
        //     if (res && res.detail) {
        //         // 更新当前表单信息
        //         this.ruleForm = Object.assign({}, this.ruleForm, res.detail)
        //     }
        // },
        async onSubmit() {
            this.$refs['ruleForm'].validate(async valid => {
                if (valid) {
                    const res = await register(this.ruleForm)
                    console.log('res', res)
                    this.$notify({ title: '提示', type: status === 1 ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
                    // if (res.detail.status !== 1) {
                    //     this.$refs['ruleForm'].resetFields()
                    // }
                }
            })
        }
    }
    // created() {
    //     this.getAccount()
    // }
}

</script>
<style lang="scss" scoped></style>
